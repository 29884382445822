import { default as awardsazbuTx2aenMeta } from "/home/forge/pininfarina-zh.quattrolinee.com/pages/about/awards.vue?macro=true";
import { default as certificationsP3IAbkyvxAMeta } from "/home/forge/pininfarina-zh.quattrolinee.com/pages/about/certifications.vue?macro=true";
import { default as heritagebJovy2znYXMeta } from "/home/forge/pininfarina-zh.quattrolinee.com/pages/about/heritage.vue?macro=true";
import { default as our_45groupcVdajeUzJrMeta } from "/home/forge/pininfarina-zh.quattrolinee.com/pages/about/our-group.vue?macro=true";
import { default as partnersd7rs21hprEMeta } from "/home/forge/pininfarina-zh.quattrolinee.com/pages/about/partners.vue?macro=true";
import { default as sustainabilityRE0ho2zPTJMeta } from "/home/forge/pininfarina-zh.quattrolinee.com/pages/about/sustainability.vue?macro=true";
import { default as teamCMs18Plx6pMeta } from "/home/forge/pininfarina-zh.quattrolinee.com/pages/about/team.vue?macro=true";
import { default as visit_45pininfarinahShBg085aWMeta } from "/home/forge/pininfarina-zh.quattrolinee.com/pages/about/visit-pininfarina.vue?macro=true";
import { default as methodologyGK2Eaum7NyMeta } from "/home/forge/pininfarina-zh.quattrolinee.com/pages/approach/methodology.vue?macro=true";
import { default as _91jobSlug_93CIQZY7qUnxMeta } from "/home/forge/pininfarina-zh.quattrolinee.com/pages/careers/[jobSlug].vue?macro=true";
import { default as indexLPBkiwvobVMeta } from "/home/forge/pininfarina-zh.quattrolinee.com/pages/careers/index.vue?macro=true";
import { default as indexkoV3M15RvkMeta } from "/home/forge/pininfarina-zh.quattrolinee.com/pages/classiche/index.vue?macro=true";
import { default as _91countrySlug_93EjLBBboc0rMeta } from "/home/forge/pininfarina-zh.quattrolinee.com/pages/contacts/[countrySlug].vue?macro=true";
import { default as indexOiQISOD2ZFMeta } from "/home/forge/pininfarina-zh.quattrolinee.com/pages/contacts/index.vue?macro=true";
import { default as messageSentSSGdbx9c12Meta } from "/home/forge/pininfarina-zh.quattrolinee.com/pages/contacts/messageSent.vue?macro=true";
import { default as cookie_45policyJrDFbi3YHJMeta } from "/home/forge/pininfarina-zh.quattrolinee.com/pages/cookie-policy.vue?macro=true";
import { default as indexnL5MQ9vc2oMeta } from "/home/forge/pininfarina-zh.quattrolinee.com/pages/index.vue?macro=true";
import { default as indexUC60cd4trOMeta } from "/home/forge/pininfarina-zh.quattrolinee.com/pages/investor-relations/[[investorSlug]]/index.vue?macro=true";
import { default as hq_45resourcesZRV3C4GMh8Meta } from "/home/forge/pininfarina-zh.quattrolinee.com/pages/media-hub/hq-resources.vue?macro=true";
import { default as indexqmXcvk1NSfMeta } from "/home/forge/pininfarina-zh.quattrolinee.com/pages/media-hub/index.vue?macro=true";
import { default as login3sDf4UZJ80Meta } from "/home/forge/pininfarina-zh.quattrolinee.com/pages/media-hub/login.vue?macro=true";
import { default as _91newsSlug_93qBLgYR6nugMeta } from "/home/forge/pininfarina-zh.quattrolinee.com/pages/news/[newsSlug].vue?macro=true";
import { default as indexhc16fBo7rtMeta } from "/home/forge/pininfarina-zh.quattrolinee.com/pages/news/index.vue?macro=true";
import { default as successmu1pJRk9V4Meta } from "/home/forge/pininfarina-zh.quattrolinee.com/pages/pardot/success.vue?macro=true";
import { default as privacy_45policy5Dqs2dZEBXMeta } from "/home/forge/pininfarina-zh.quattrolinee.com/pages/privacy-policy.vue?macro=true";
import { default as _91projectSlug_9305MQPmuP1uMeta } from "/home/forge/pininfarina-zh.quattrolinee.com/pages/projects/[projectSlug].vue?macro=true";
import { default as indexxI5TSxFy4VMeta } from "/home/forge/pininfarina-zh.quattrolinee.com/pages/search/index.vue?macro=true";
import { default as indexHGRz3ODMtFMeta } from "/home/forge/pininfarina-zh.quattrolinee.com/pages/sectors/[sectorSlug]/index.vue?macro=true";
import { default as portfolioAKXTCsFfk7Meta } from "/home/forge/pininfarina-zh.quattrolinee.com/pages/sectors/[sectorSlug]/portfolio.vue?macro=true";
import { default as _91serviceSlug_93jNNWsvHvexMeta } from "/home/forge/pininfarina-zh.quattrolinee.com/pages/services/[serviceSlug].vue?macro=true";
import { default as indexTEG15DsSfqMeta } from "/home/forge/pininfarina-zh.quattrolinee.com/pages/whistleblowing/index.vue?macro=true";
import { default as pininfarina_45zh_46quattrolineeo2BPDcoM46Meta } from "/home/forge/pininfarina-zh.quattrolinee.com/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
export default [
  {
    name: awardsazbuTx2aenMeta?.name ?? "about-awards___de",
    path: awardsazbuTx2aenMeta?.path ?? "/de/um/auszeichnungen",
    meta: awardsazbuTx2aenMeta || {},
    alias: awardsazbuTx2aenMeta?.alias || [],
    redirect: awardsazbuTx2aenMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/about/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsazbuTx2aenMeta?.name ?? "about-awards___en___default",
    path: awardsazbuTx2aenMeta?.path ?? "/about/awards",
    meta: awardsazbuTx2aenMeta || {},
    alias: awardsazbuTx2aenMeta?.alias || [],
    redirect: awardsazbuTx2aenMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/about/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsazbuTx2aenMeta?.name ?? "about-awards___en",
    path: awardsazbuTx2aenMeta?.path ?? "/en/about/awards",
    meta: awardsazbuTx2aenMeta || {},
    alias: awardsazbuTx2aenMeta?.alias || [],
    redirect: awardsazbuTx2aenMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/about/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsazbuTx2aenMeta?.name ?? "about-awards___it",
    path: awardsazbuTx2aenMeta?.path ?? "/it/about/premi",
    meta: awardsazbuTx2aenMeta || {},
    alias: awardsazbuTx2aenMeta?.alias || [],
    redirect: awardsazbuTx2aenMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/about/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsazbuTx2aenMeta?.name ?? "about-awards___zh",
    path: awardsazbuTx2aenMeta?.path ?? "/zh/guanyu/jiangxiang",
    meta: awardsazbuTx2aenMeta || {},
    alias: awardsazbuTx2aenMeta?.alias || [],
    redirect: awardsazbuTx2aenMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/about/awards.vue").then(m => m.default || m)
  },
  {
    name: certificationsP3IAbkyvxAMeta?.name ?? "about-certifications___de",
    path: certificationsP3IAbkyvxAMeta?.path ?? "/de/about/certifications",
    meta: certificationsP3IAbkyvxAMeta || {},
    alias: certificationsP3IAbkyvxAMeta?.alias || [],
    redirect: certificationsP3IAbkyvxAMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/about/certifications.vue").then(m => m.default || m)
  },
  {
    name: certificationsP3IAbkyvxAMeta?.name ?? "about-certifications___en___default",
    path: certificationsP3IAbkyvxAMeta?.path ?? "/about/certifications",
    meta: certificationsP3IAbkyvxAMeta || {},
    alias: certificationsP3IAbkyvxAMeta?.alias || [],
    redirect: certificationsP3IAbkyvxAMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/about/certifications.vue").then(m => m.default || m)
  },
  {
    name: certificationsP3IAbkyvxAMeta?.name ?? "about-certifications___en",
    path: certificationsP3IAbkyvxAMeta?.path ?? "/en/about/certifications",
    meta: certificationsP3IAbkyvxAMeta || {},
    alias: certificationsP3IAbkyvxAMeta?.alias || [],
    redirect: certificationsP3IAbkyvxAMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/about/certifications.vue").then(m => m.default || m)
  },
  {
    name: certificationsP3IAbkyvxAMeta?.name ?? "about-certifications___it",
    path: certificationsP3IAbkyvxAMeta?.path ?? "/it/about/certifications",
    meta: certificationsP3IAbkyvxAMeta || {},
    alias: certificationsP3IAbkyvxAMeta?.alias || [],
    redirect: certificationsP3IAbkyvxAMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/about/certifications.vue").then(m => m.default || m)
  },
  {
    name: certificationsP3IAbkyvxAMeta?.name ?? "about-certifications___zh",
    path: certificationsP3IAbkyvxAMeta?.path ?? "/zh/about/certifications",
    meta: certificationsP3IAbkyvxAMeta || {},
    alias: certificationsP3IAbkyvxAMeta?.alias || [],
    redirect: certificationsP3IAbkyvxAMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/about/certifications.vue").then(m => m.default || m)
  },
  {
    name: heritagebJovy2znYXMeta?.name ?? "about-heritage___de",
    path: heritagebJovy2znYXMeta?.path ?? "/de/um/erbe",
    meta: heritagebJovy2znYXMeta || {},
    alias: heritagebJovy2znYXMeta?.alias || [],
    redirect: heritagebJovy2znYXMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/about/heritage.vue").then(m => m.default || m)
  },
  {
    name: heritagebJovy2znYXMeta?.name ?? "about-heritage___en___default",
    path: heritagebJovy2znYXMeta?.path ?? "/about/heritage",
    meta: heritagebJovy2znYXMeta || {},
    alias: heritagebJovy2znYXMeta?.alias || [],
    redirect: heritagebJovy2znYXMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/about/heritage.vue").then(m => m.default || m)
  },
  {
    name: heritagebJovy2znYXMeta?.name ?? "about-heritage___en",
    path: heritagebJovy2znYXMeta?.path ?? "/en/about/heritage",
    meta: heritagebJovy2znYXMeta || {},
    alias: heritagebJovy2znYXMeta?.alias || [],
    redirect: heritagebJovy2znYXMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/about/heritage.vue").then(m => m.default || m)
  },
  {
    name: heritagebJovy2znYXMeta?.name ?? "about-heritage___it",
    path: heritagebJovy2znYXMeta?.path ?? "/it/about/heritage",
    meta: heritagebJovy2znYXMeta || {},
    alias: heritagebJovy2znYXMeta?.alias || [],
    redirect: heritagebJovy2znYXMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/about/heritage.vue").then(m => m.default || m)
  },
  {
    name: heritagebJovy2znYXMeta?.name ?? "about-heritage___zh",
    path: heritagebJovy2znYXMeta?.path ?? "/zh/guanyu/yichan",
    meta: heritagebJovy2znYXMeta || {},
    alias: heritagebJovy2znYXMeta?.alias || [],
    redirect: heritagebJovy2znYXMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/about/heritage.vue").then(m => m.default || m)
  },
  {
    name: our_45groupcVdajeUzJrMeta?.name ?? "about-our-group___de",
    path: our_45groupcVdajeUzJrMeta?.path ?? "/de/um/unsere-gruppe",
    meta: our_45groupcVdajeUzJrMeta || {},
    alias: our_45groupcVdajeUzJrMeta?.alias || [],
    redirect: our_45groupcVdajeUzJrMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/about/our-group.vue").then(m => m.default || m)
  },
  {
    name: our_45groupcVdajeUzJrMeta?.name ?? "about-our-group___en___default",
    path: our_45groupcVdajeUzJrMeta?.path ?? "/about/our-group",
    meta: our_45groupcVdajeUzJrMeta || {},
    alias: our_45groupcVdajeUzJrMeta?.alias || [],
    redirect: our_45groupcVdajeUzJrMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/about/our-group.vue").then(m => m.default || m)
  },
  {
    name: our_45groupcVdajeUzJrMeta?.name ?? "about-our-group___en",
    path: our_45groupcVdajeUzJrMeta?.path ?? "/en/about/our-group",
    meta: our_45groupcVdajeUzJrMeta || {},
    alias: our_45groupcVdajeUzJrMeta?.alias || [],
    redirect: our_45groupcVdajeUzJrMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/about/our-group.vue").then(m => m.default || m)
  },
  {
    name: our_45groupcVdajeUzJrMeta?.name ?? "about-our-group___it",
    path: our_45groupcVdajeUzJrMeta?.path ?? "/it/about/il-gruppo",
    meta: our_45groupcVdajeUzJrMeta || {},
    alias: our_45groupcVdajeUzJrMeta?.alias || [],
    redirect: our_45groupcVdajeUzJrMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/about/our-group.vue").then(m => m.default || m)
  },
  {
    name: our_45groupcVdajeUzJrMeta?.name ?? "about-our-group___zh",
    path: our_45groupcVdajeUzJrMeta?.path ?? "/zh/guanyu/women-de-zu",
    meta: our_45groupcVdajeUzJrMeta || {},
    alias: our_45groupcVdajeUzJrMeta?.alias || [],
    redirect: our_45groupcVdajeUzJrMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/about/our-group.vue").then(m => m.default || m)
  },
  {
    name: partnersd7rs21hprEMeta?.name ?? "about-partners___de",
    path: partnersd7rs21hprEMeta?.path ?? "/de/about/partners",
    meta: partnersd7rs21hprEMeta || {},
    alias: partnersd7rs21hprEMeta?.alias || [],
    redirect: partnersd7rs21hprEMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/about/partners.vue").then(m => m.default || m)
  },
  {
    name: partnersd7rs21hprEMeta?.name ?? "about-partners___en___default",
    path: partnersd7rs21hprEMeta?.path ?? "/about/partners",
    meta: partnersd7rs21hprEMeta || {},
    alias: partnersd7rs21hprEMeta?.alias || [],
    redirect: partnersd7rs21hprEMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/about/partners.vue").then(m => m.default || m)
  },
  {
    name: partnersd7rs21hprEMeta?.name ?? "about-partners___en",
    path: partnersd7rs21hprEMeta?.path ?? "/en/about/partners",
    meta: partnersd7rs21hprEMeta || {},
    alias: partnersd7rs21hprEMeta?.alias || [],
    redirect: partnersd7rs21hprEMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/about/partners.vue").then(m => m.default || m)
  },
  {
    name: partnersd7rs21hprEMeta?.name ?? "about-partners___it",
    path: partnersd7rs21hprEMeta?.path ?? "/it/about/partners",
    meta: partnersd7rs21hprEMeta || {},
    alias: partnersd7rs21hprEMeta?.alias || [],
    redirect: partnersd7rs21hprEMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/about/partners.vue").then(m => m.default || m)
  },
  {
    name: partnersd7rs21hprEMeta?.name ?? "about-partners___zh",
    path: partnersd7rs21hprEMeta?.path ?? "/zh/about/partners",
    meta: partnersd7rs21hprEMeta || {},
    alias: partnersd7rs21hprEMeta?.alias || [],
    redirect: partnersd7rs21hprEMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/about/partners.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityRE0ho2zPTJMeta?.name ?? "about-sustainability___de",
    path: sustainabilityRE0ho2zPTJMeta?.path ?? "/de/um/nachhaltigkeit",
    meta: sustainabilityRE0ho2zPTJMeta || {},
    alias: sustainabilityRE0ho2zPTJMeta?.alias || [],
    redirect: sustainabilityRE0ho2zPTJMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/about/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityRE0ho2zPTJMeta?.name ?? "about-sustainability___en___default",
    path: sustainabilityRE0ho2zPTJMeta?.path ?? "/about/sustainability",
    meta: sustainabilityRE0ho2zPTJMeta || {},
    alias: sustainabilityRE0ho2zPTJMeta?.alias || [],
    redirect: sustainabilityRE0ho2zPTJMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/about/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityRE0ho2zPTJMeta?.name ?? "about-sustainability___en",
    path: sustainabilityRE0ho2zPTJMeta?.path ?? "/en/about/sustainability",
    meta: sustainabilityRE0ho2zPTJMeta || {},
    alias: sustainabilityRE0ho2zPTJMeta?.alias || [],
    redirect: sustainabilityRE0ho2zPTJMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/about/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityRE0ho2zPTJMeta?.name ?? "about-sustainability___it",
    path: sustainabilityRE0ho2zPTJMeta?.path ?? "/it/about/sostenibilita",
    meta: sustainabilityRE0ho2zPTJMeta || {},
    alias: sustainabilityRE0ho2zPTJMeta?.alias || [],
    redirect: sustainabilityRE0ho2zPTJMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/about/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityRE0ho2zPTJMeta?.name ?? "about-sustainability___zh",
    path: sustainabilityRE0ho2zPTJMeta?.path ?? "/zh/guanyu/ke-chixu-xing",
    meta: sustainabilityRE0ho2zPTJMeta || {},
    alias: sustainabilityRE0ho2zPTJMeta?.alias || [],
    redirect: sustainabilityRE0ho2zPTJMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/about/sustainability.vue").then(m => m.default || m)
  },
  {
    name: teamCMs18Plx6pMeta?.name ?? "about-team___de",
    path: teamCMs18Plx6pMeta?.path ?? "/de/um/team",
    meta: teamCMs18Plx6pMeta || {},
    alias: teamCMs18Plx6pMeta?.alias || [],
    redirect: teamCMs18Plx6pMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/about/team.vue").then(m => m.default || m)
  },
  {
    name: teamCMs18Plx6pMeta?.name ?? "about-team___en___default",
    path: teamCMs18Plx6pMeta?.path ?? "/about/team",
    meta: teamCMs18Plx6pMeta || {},
    alias: teamCMs18Plx6pMeta?.alias || [],
    redirect: teamCMs18Plx6pMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/about/team.vue").then(m => m.default || m)
  },
  {
    name: teamCMs18Plx6pMeta?.name ?? "about-team___en",
    path: teamCMs18Plx6pMeta?.path ?? "/en/about/team",
    meta: teamCMs18Plx6pMeta || {},
    alias: teamCMs18Plx6pMeta?.alias || [],
    redirect: teamCMs18Plx6pMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/about/team.vue").then(m => m.default || m)
  },
  {
    name: teamCMs18Plx6pMeta?.name ?? "about-team___it",
    path: teamCMs18Plx6pMeta?.path ?? "/it/about/team",
    meta: teamCMs18Plx6pMeta || {},
    alias: teamCMs18Plx6pMeta?.alias || [],
    redirect: teamCMs18Plx6pMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/about/team.vue").then(m => m.default || m)
  },
  {
    name: teamCMs18Plx6pMeta?.name ?? "about-team___zh",
    path: teamCMs18Plx6pMeta?.path ?? "/zh/guanyu/tuandui",
    meta: teamCMs18Plx6pMeta || {},
    alias: teamCMs18Plx6pMeta?.alias || [],
    redirect: teamCMs18Plx6pMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/about/team.vue").then(m => m.default || m)
  },
  {
    name: visit_45pininfarinahShBg085aWMeta?.name ?? "about-visit-pininfarina___de",
    path: visit_45pininfarinahShBg085aWMeta?.path ?? "/de/um/besuchen-sie-pininfarina",
    meta: visit_45pininfarinahShBg085aWMeta || {},
    alias: visit_45pininfarinahShBg085aWMeta?.alias || [],
    redirect: visit_45pininfarinahShBg085aWMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/about/visit-pininfarina.vue").then(m => m.default || m)
  },
  {
    name: visit_45pininfarinahShBg085aWMeta?.name ?? "about-visit-pininfarina___en___default",
    path: visit_45pininfarinahShBg085aWMeta?.path ?? "/about/visit-pininfarina",
    meta: visit_45pininfarinahShBg085aWMeta || {},
    alias: visit_45pininfarinahShBg085aWMeta?.alias || [],
    redirect: visit_45pininfarinahShBg085aWMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/about/visit-pininfarina.vue").then(m => m.default || m)
  },
  {
    name: visit_45pininfarinahShBg085aWMeta?.name ?? "about-visit-pininfarina___en",
    path: visit_45pininfarinahShBg085aWMeta?.path ?? "/en/about/visit-pininfarina",
    meta: visit_45pininfarinahShBg085aWMeta || {},
    alias: visit_45pininfarinahShBg085aWMeta?.alias || [],
    redirect: visit_45pininfarinahShBg085aWMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/about/visit-pininfarina.vue").then(m => m.default || m)
  },
  {
    name: visit_45pininfarinahShBg085aWMeta?.name ?? "about-visit-pininfarina___it",
    path: visit_45pininfarinahShBg085aWMeta?.path ?? "/it/about/visita-pininfarina",
    meta: visit_45pininfarinahShBg085aWMeta || {},
    alias: visit_45pininfarinahShBg085aWMeta?.alias || [],
    redirect: visit_45pininfarinahShBg085aWMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/about/visit-pininfarina.vue").then(m => m.default || m)
  },
  {
    name: visit_45pininfarinahShBg085aWMeta?.name ?? "about-visit-pininfarina___zh",
    path: visit_45pininfarinahShBg085aWMeta?.path ?? "/zh/guanyu/hui-pininfarina",
    meta: visit_45pininfarinahShBg085aWMeta || {},
    alias: visit_45pininfarinahShBg085aWMeta?.alias || [],
    redirect: visit_45pininfarinahShBg085aWMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/about/visit-pininfarina.vue").then(m => m.default || m)
  },
  {
    name: methodologyGK2Eaum7NyMeta?.name ?? "approach-methodology___de",
    path: methodologyGK2Eaum7NyMeta?.path ?? "/de/approach/methodology",
    meta: methodologyGK2Eaum7NyMeta || {},
    alias: methodologyGK2Eaum7NyMeta?.alias || [],
    redirect: methodologyGK2Eaum7NyMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/approach/methodology.vue").then(m => m.default || m)
  },
  {
    name: methodologyGK2Eaum7NyMeta?.name ?? "approach-methodology___en___default",
    path: methodologyGK2Eaum7NyMeta?.path ?? "/approach/methodology",
    meta: methodologyGK2Eaum7NyMeta || {},
    alias: methodologyGK2Eaum7NyMeta?.alias || [],
    redirect: methodologyGK2Eaum7NyMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/approach/methodology.vue").then(m => m.default || m)
  },
  {
    name: methodologyGK2Eaum7NyMeta?.name ?? "approach-methodology___en",
    path: methodologyGK2Eaum7NyMeta?.path ?? "/en/approach/methodology",
    meta: methodologyGK2Eaum7NyMeta || {},
    alias: methodologyGK2Eaum7NyMeta?.alias || [],
    redirect: methodologyGK2Eaum7NyMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/approach/methodology.vue").then(m => m.default || m)
  },
  {
    name: methodologyGK2Eaum7NyMeta?.name ?? "approach-methodology___it",
    path: methodologyGK2Eaum7NyMeta?.path ?? "/it/approach/methodology",
    meta: methodologyGK2Eaum7NyMeta || {},
    alias: methodologyGK2Eaum7NyMeta?.alias || [],
    redirect: methodologyGK2Eaum7NyMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/approach/methodology.vue").then(m => m.default || m)
  },
  {
    name: methodologyGK2Eaum7NyMeta?.name ?? "approach-methodology___zh",
    path: methodologyGK2Eaum7NyMeta?.path ?? "/zh/approach/methodology",
    meta: methodologyGK2Eaum7NyMeta || {},
    alias: methodologyGK2Eaum7NyMeta?.alias || [],
    redirect: methodologyGK2Eaum7NyMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/approach/methodology.vue").then(m => m.default || m)
  },
  {
    name: _91jobSlug_93CIQZY7qUnxMeta?.name ?? "careers-jobSlug___de",
    path: _91jobSlug_93CIQZY7qUnxMeta?.path ?? "/de/karriere/:jobSlug",
    meta: _91jobSlug_93CIQZY7qUnxMeta || {},
    alias: _91jobSlug_93CIQZY7qUnxMeta?.alias || [],
    redirect: _91jobSlug_93CIQZY7qUnxMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/careers/[jobSlug].vue").then(m => m.default || m)
  },
  {
    name: _91jobSlug_93CIQZY7qUnxMeta?.name ?? "careers-jobSlug___en___default",
    path: _91jobSlug_93CIQZY7qUnxMeta?.path ?? "/careers/:jobSlug",
    meta: _91jobSlug_93CIQZY7qUnxMeta || {},
    alias: _91jobSlug_93CIQZY7qUnxMeta?.alias || [],
    redirect: _91jobSlug_93CIQZY7qUnxMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/careers/[jobSlug].vue").then(m => m.default || m)
  },
  {
    name: _91jobSlug_93CIQZY7qUnxMeta?.name ?? "careers-jobSlug___en",
    path: _91jobSlug_93CIQZY7qUnxMeta?.path ?? "/en/careers/:jobSlug",
    meta: _91jobSlug_93CIQZY7qUnxMeta || {},
    alias: _91jobSlug_93CIQZY7qUnxMeta?.alias || [],
    redirect: _91jobSlug_93CIQZY7qUnxMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/careers/[jobSlug].vue").then(m => m.default || m)
  },
  {
    name: _91jobSlug_93CIQZY7qUnxMeta?.name ?? "careers-jobSlug___it",
    path: _91jobSlug_93CIQZY7qUnxMeta?.path ?? "/it/carriere/:jobSlug",
    meta: _91jobSlug_93CIQZY7qUnxMeta || {},
    alias: _91jobSlug_93CIQZY7qUnxMeta?.alias || [],
    redirect: _91jobSlug_93CIQZY7qUnxMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/careers/[jobSlug].vue").then(m => m.default || m)
  },
  {
    name: _91jobSlug_93CIQZY7qUnxMeta?.name ?? "careers-jobSlug___zh",
    path: _91jobSlug_93CIQZY7qUnxMeta?.path ?? "/zh/zhiye-shengya/:jobSlug",
    meta: _91jobSlug_93CIQZY7qUnxMeta || {},
    alias: _91jobSlug_93CIQZY7qUnxMeta?.alias || [],
    redirect: _91jobSlug_93CIQZY7qUnxMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/careers/[jobSlug].vue").then(m => m.default || m)
  },
  {
    name: indexLPBkiwvobVMeta?.name ?? "careers___de",
    path: indexLPBkiwvobVMeta?.path ?? "/de/karriere",
    meta: indexLPBkiwvobVMeta || {},
    alias: indexLPBkiwvobVMeta?.alias || [],
    redirect: indexLPBkiwvobVMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexLPBkiwvobVMeta?.name ?? "careers___en___default",
    path: indexLPBkiwvobVMeta?.path ?? "/careers/",
    meta: indexLPBkiwvobVMeta || {},
    alias: indexLPBkiwvobVMeta?.alias || [],
    redirect: indexLPBkiwvobVMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexLPBkiwvobVMeta?.name ?? "careers___en",
    path: indexLPBkiwvobVMeta?.path ?? "/en/careers",
    meta: indexLPBkiwvobVMeta || {},
    alias: indexLPBkiwvobVMeta?.alias || [],
    redirect: indexLPBkiwvobVMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexLPBkiwvobVMeta?.name ?? "careers___it",
    path: indexLPBkiwvobVMeta?.path ?? "/it/carriere",
    meta: indexLPBkiwvobVMeta || {},
    alias: indexLPBkiwvobVMeta?.alias || [],
    redirect: indexLPBkiwvobVMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexLPBkiwvobVMeta?.name ?? "careers___zh",
    path: indexLPBkiwvobVMeta?.path ?? "/zh/zhiye-shengya",
    meta: indexLPBkiwvobVMeta || {},
    alias: indexLPBkiwvobVMeta?.alias || [],
    redirect: indexLPBkiwvobVMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexkoV3M15RvkMeta?.name ?? "classiche___de",
    path: indexkoV3M15RvkMeta?.path ?? "/de/classiche",
    meta: indexkoV3M15RvkMeta || {},
    alias: indexkoV3M15RvkMeta?.alias || [],
    redirect: indexkoV3M15RvkMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/classiche/index.vue").then(m => m.default || m)
  },
  {
    name: indexkoV3M15RvkMeta?.name ?? "classiche___en___default",
    path: indexkoV3M15RvkMeta?.path ?? "/classiche/",
    meta: indexkoV3M15RvkMeta || {},
    alias: indexkoV3M15RvkMeta?.alias || [],
    redirect: indexkoV3M15RvkMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/classiche/index.vue").then(m => m.default || m)
  },
  {
    name: indexkoV3M15RvkMeta?.name ?? "classiche___en",
    path: indexkoV3M15RvkMeta?.path ?? "/en/classiche",
    meta: indexkoV3M15RvkMeta || {},
    alias: indexkoV3M15RvkMeta?.alias || [],
    redirect: indexkoV3M15RvkMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/classiche/index.vue").then(m => m.default || m)
  },
  {
    name: indexkoV3M15RvkMeta?.name ?? "classiche___it",
    path: indexkoV3M15RvkMeta?.path ?? "/it/classiche",
    meta: indexkoV3M15RvkMeta || {},
    alias: indexkoV3M15RvkMeta?.alias || [],
    redirect: indexkoV3M15RvkMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/classiche/index.vue").then(m => m.default || m)
  },
  {
    name: indexkoV3M15RvkMeta?.name ?? "classiche___zh",
    path: indexkoV3M15RvkMeta?.path ?? "/zh/classiche",
    meta: indexkoV3M15RvkMeta || {},
    alias: indexkoV3M15RvkMeta?.alias || [],
    redirect: indexkoV3M15RvkMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/classiche/index.vue").then(m => m.default || m)
  },
  {
    name: _91countrySlug_93EjLBBboc0rMeta?.name ?? "contacts-countrySlug___de",
    path: _91countrySlug_93EjLBBboc0rMeta?.path ?? "/de/kontakte/:countrySlug",
    meta: _91countrySlug_93EjLBBboc0rMeta || {},
    alias: _91countrySlug_93EjLBBboc0rMeta?.alias || [],
    redirect: _91countrySlug_93EjLBBboc0rMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/contacts/[countrySlug].vue").then(m => m.default || m)
  },
  {
    name: _91countrySlug_93EjLBBboc0rMeta?.name ?? "contacts-countrySlug___en___default",
    path: _91countrySlug_93EjLBBboc0rMeta?.path ?? "/contacts/:countrySlug",
    meta: _91countrySlug_93EjLBBboc0rMeta || {},
    alias: _91countrySlug_93EjLBBboc0rMeta?.alias || [],
    redirect: _91countrySlug_93EjLBBboc0rMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/contacts/[countrySlug].vue").then(m => m.default || m)
  },
  {
    name: _91countrySlug_93EjLBBboc0rMeta?.name ?? "contacts-countrySlug___en",
    path: _91countrySlug_93EjLBBboc0rMeta?.path ?? "/en/contacts/:countrySlug",
    meta: _91countrySlug_93EjLBBboc0rMeta || {},
    alias: _91countrySlug_93EjLBBboc0rMeta?.alias || [],
    redirect: _91countrySlug_93EjLBBboc0rMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/contacts/[countrySlug].vue").then(m => m.default || m)
  },
  {
    name: _91countrySlug_93EjLBBboc0rMeta?.name ?? "contacts-countrySlug___it",
    path: _91countrySlug_93EjLBBboc0rMeta?.path ?? "/it/contatti/:countrySlug",
    meta: _91countrySlug_93EjLBBboc0rMeta || {},
    alias: _91countrySlug_93EjLBBboc0rMeta?.alias || [],
    redirect: _91countrySlug_93EjLBBboc0rMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/contacts/[countrySlug].vue").then(m => m.default || m)
  },
  {
    name: _91countrySlug_93EjLBBboc0rMeta?.name ?? "contacts-countrySlug___zh",
    path: _91countrySlug_93EjLBBboc0rMeta?.path ?? "/zh/lianxi-ren/:countrySlug",
    meta: _91countrySlug_93EjLBBboc0rMeta || {},
    alias: _91countrySlug_93EjLBBboc0rMeta?.alias || [],
    redirect: _91countrySlug_93EjLBBboc0rMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/contacts/[countrySlug].vue").then(m => m.default || m)
  },
  {
    name: indexOiQISOD2ZFMeta?.name ?? "contacts___de",
    path: indexOiQISOD2ZFMeta?.path ?? "/de/kontakte/unsere-gruppe",
    meta: indexOiQISOD2ZFMeta || {},
    alias: indexOiQISOD2ZFMeta?.alias || [],
    redirect: indexOiQISOD2ZFMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: indexOiQISOD2ZFMeta?.name ?? "contacts___en___default",
    path: indexOiQISOD2ZFMeta?.path ?? "/contacts/our-offices",
    meta: indexOiQISOD2ZFMeta || {},
    alias: indexOiQISOD2ZFMeta?.alias || [],
    redirect: indexOiQISOD2ZFMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: indexOiQISOD2ZFMeta?.name ?? "contacts___en",
    path: indexOiQISOD2ZFMeta?.path ?? "/en/contacts/our-offices",
    meta: indexOiQISOD2ZFMeta || {},
    alias: indexOiQISOD2ZFMeta?.alias || [],
    redirect: indexOiQISOD2ZFMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: indexOiQISOD2ZFMeta?.name ?? "contacts___it",
    path: indexOiQISOD2ZFMeta?.path ?? "/it/contatti/i-nostri-uffici",
    meta: indexOiQISOD2ZFMeta || {},
    alias: indexOiQISOD2ZFMeta?.alias || [],
    redirect: indexOiQISOD2ZFMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: indexOiQISOD2ZFMeta?.name ?? "contacts___zh",
    path: indexOiQISOD2ZFMeta?.path ?? "/zh/lianxi-ren/women-de-zu",
    meta: indexOiQISOD2ZFMeta || {},
    alias: indexOiQISOD2ZFMeta?.alias || [],
    redirect: indexOiQISOD2ZFMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: messageSentSSGdbx9c12Meta?.name ?? "contacts-messageSent___de",
    path: messageSentSSGdbx9c12Meta?.path ?? "/de/message-sent",
    meta: messageSentSSGdbx9c12Meta || {},
    alias: messageSentSSGdbx9c12Meta?.alias || [],
    redirect: messageSentSSGdbx9c12Meta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/contacts/messageSent.vue").then(m => m.default || m)
  },
  {
    name: messageSentSSGdbx9c12Meta?.name ?? "contacts-messageSent___en___default",
    path: messageSentSSGdbx9c12Meta?.path ?? "/message-sent",
    meta: messageSentSSGdbx9c12Meta || {},
    alias: messageSentSSGdbx9c12Meta?.alias || [],
    redirect: messageSentSSGdbx9c12Meta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/contacts/messageSent.vue").then(m => m.default || m)
  },
  {
    name: messageSentSSGdbx9c12Meta?.name ?? "contacts-messageSent___en",
    path: messageSentSSGdbx9c12Meta?.path ?? "/en/message-sent",
    meta: messageSentSSGdbx9c12Meta || {},
    alias: messageSentSSGdbx9c12Meta?.alias || [],
    redirect: messageSentSSGdbx9c12Meta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/contacts/messageSent.vue").then(m => m.default || m)
  },
  {
    name: messageSentSSGdbx9c12Meta?.name ?? "contacts-messageSent___it",
    path: messageSentSSGdbx9c12Meta?.path ?? "/it/message-sent",
    meta: messageSentSSGdbx9c12Meta || {},
    alias: messageSentSSGdbx9c12Meta?.alias || [],
    redirect: messageSentSSGdbx9c12Meta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/contacts/messageSent.vue").then(m => m.default || m)
  },
  {
    name: messageSentSSGdbx9c12Meta?.name ?? "contacts-messageSent___zh",
    path: messageSentSSGdbx9c12Meta?.path ?? "/zh/message-sent",
    meta: messageSentSSGdbx9c12Meta || {},
    alias: messageSentSSGdbx9c12Meta?.alias || [],
    redirect: messageSentSSGdbx9c12Meta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/contacts/messageSent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyJrDFbi3YHJMeta?.name ?? "cookie-policy___de",
    path: cookie_45policyJrDFbi3YHJMeta?.path ?? "/de/cookie-policy",
    meta: cookie_45policyJrDFbi3YHJMeta || {},
    alias: cookie_45policyJrDFbi3YHJMeta?.alias || [],
    redirect: cookie_45policyJrDFbi3YHJMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyJrDFbi3YHJMeta?.name ?? "cookie-policy___en___default",
    path: cookie_45policyJrDFbi3YHJMeta?.path ?? "/cookie-policy/",
    meta: cookie_45policyJrDFbi3YHJMeta || {},
    alias: cookie_45policyJrDFbi3YHJMeta?.alias || [],
    redirect: cookie_45policyJrDFbi3YHJMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyJrDFbi3YHJMeta?.name ?? "cookie-policy___en",
    path: cookie_45policyJrDFbi3YHJMeta?.path ?? "/en/cookie-policy",
    meta: cookie_45policyJrDFbi3YHJMeta || {},
    alias: cookie_45policyJrDFbi3YHJMeta?.alias || [],
    redirect: cookie_45policyJrDFbi3YHJMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyJrDFbi3YHJMeta?.name ?? "cookie-policy___it",
    path: cookie_45policyJrDFbi3YHJMeta?.path ?? "/it/cookie-policy",
    meta: cookie_45policyJrDFbi3YHJMeta || {},
    alias: cookie_45policyJrDFbi3YHJMeta?.alias || [],
    redirect: cookie_45policyJrDFbi3YHJMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyJrDFbi3YHJMeta?.name ?? "cookie-policy___zh",
    path: cookie_45policyJrDFbi3YHJMeta?.path ?? "/zh/cookie-policy",
    meta: cookie_45policyJrDFbi3YHJMeta || {},
    alias: cookie_45policyJrDFbi3YHJMeta?.alias || [],
    redirect: cookie_45policyJrDFbi3YHJMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: indexnL5MQ9vc2oMeta?.name ?? "index___de",
    path: indexnL5MQ9vc2oMeta?.path ?? "/de",
    meta: indexnL5MQ9vc2oMeta || {},
    alias: indexnL5MQ9vc2oMeta?.alias || [],
    redirect: indexnL5MQ9vc2oMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexnL5MQ9vc2oMeta?.name ?? "index___en___default",
    path: indexnL5MQ9vc2oMeta?.path ?? "/",
    meta: indexnL5MQ9vc2oMeta || {},
    alias: indexnL5MQ9vc2oMeta?.alias || [],
    redirect: indexnL5MQ9vc2oMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexnL5MQ9vc2oMeta?.name ?? "index___en",
    path: indexnL5MQ9vc2oMeta?.path ?? "/en",
    meta: indexnL5MQ9vc2oMeta || {},
    alias: indexnL5MQ9vc2oMeta?.alias || [],
    redirect: indexnL5MQ9vc2oMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexnL5MQ9vc2oMeta?.name ?? "index___it",
    path: indexnL5MQ9vc2oMeta?.path ?? "/it",
    meta: indexnL5MQ9vc2oMeta || {},
    alias: indexnL5MQ9vc2oMeta?.alias || [],
    redirect: indexnL5MQ9vc2oMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexnL5MQ9vc2oMeta?.name ?? "index___zh",
    path: indexnL5MQ9vc2oMeta?.path ?? "/zh",
    meta: indexnL5MQ9vc2oMeta || {},
    alias: indexnL5MQ9vc2oMeta?.alias || [],
    redirect: indexnL5MQ9vc2oMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexUC60cd4trOMeta?.name ?? "investor-relations-investorSlug___de",
    path: indexUC60cd4trOMeta?.path ?? "/de/investor-relations/:investorSlug?",
    meta: indexUC60cd4trOMeta || {},
    alias: indexUC60cd4trOMeta?.alias || [],
    redirect: indexUC60cd4trOMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/investor-relations/[[investorSlug]]/index.vue").then(m => m.default || m)
  },
  {
    name: indexUC60cd4trOMeta?.name ?? "investor-relations-investorSlug___en___default",
    path: indexUC60cd4trOMeta?.path ?? "/investor-relations/:investorSlug?",
    meta: indexUC60cd4trOMeta || {},
    alias: indexUC60cd4trOMeta?.alias || [],
    redirect: indexUC60cd4trOMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/investor-relations/[[investorSlug]]/index.vue").then(m => m.default || m)
  },
  {
    name: indexUC60cd4trOMeta?.name ?? "investor-relations-investorSlug___en",
    path: indexUC60cd4trOMeta?.path ?? "/en/investor-relations/:investorSlug?",
    meta: indexUC60cd4trOMeta || {},
    alias: indexUC60cd4trOMeta?.alias || [],
    redirect: indexUC60cd4trOMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/investor-relations/[[investorSlug]]/index.vue").then(m => m.default || m)
  },
  {
    name: indexUC60cd4trOMeta?.name ?? "investor-relations-investorSlug___it",
    path: indexUC60cd4trOMeta?.path ?? "/it/investor-relations/:investorSlug?",
    meta: indexUC60cd4trOMeta || {},
    alias: indexUC60cd4trOMeta?.alias || [],
    redirect: indexUC60cd4trOMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/investor-relations/[[investorSlug]]/index.vue").then(m => m.default || m)
  },
  {
    name: indexUC60cd4trOMeta?.name ?? "investor-relations-investorSlug___zh",
    path: indexUC60cd4trOMeta?.path ?? "/zh/investor-relations/:investorSlug?",
    meta: indexUC60cd4trOMeta || {},
    alias: indexUC60cd4trOMeta?.alias || [],
    redirect: indexUC60cd4trOMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/investor-relations/[[investorSlug]]/index.vue").then(m => m.default || m)
  },
  {
    name: hq_45resourcesZRV3C4GMh8Meta?.name ?? "media-hub-hq-resources___de",
    path: hq_45resourcesZRV3C4GMh8Meta?.path ?? "/de/media-hub/hq-resources",
    meta: hq_45resourcesZRV3C4GMh8Meta || {},
    alias: hq_45resourcesZRV3C4GMh8Meta?.alias || [],
    redirect: hq_45resourcesZRV3C4GMh8Meta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/media-hub/hq-resources.vue").then(m => m.default || m)
  },
  {
    name: hq_45resourcesZRV3C4GMh8Meta?.name ?? "media-hub-hq-resources___en___default",
    path: hq_45resourcesZRV3C4GMh8Meta?.path ?? "/media-hub/hq-resources",
    meta: hq_45resourcesZRV3C4GMh8Meta || {},
    alias: hq_45resourcesZRV3C4GMh8Meta?.alias || [],
    redirect: hq_45resourcesZRV3C4GMh8Meta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/media-hub/hq-resources.vue").then(m => m.default || m)
  },
  {
    name: hq_45resourcesZRV3C4GMh8Meta?.name ?? "media-hub-hq-resources___en",
    path: hq_45resourcesZRV3C4GMh8Meta?.path ?? "/en/media-hub/hq-resources",
    meta: hq_45resourcesZRV3C4GMh8Meta || {},
    alias: hq_45resourcesZRV3C4GMh8Meta?.alias || [],
    redirect: hq_45resourcesZRV3C4GMh8Meta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/media-hub/hq-resources.vue").then(m => m.default || m)
  },
  {
    name: hq_45resourcesZRV3C4GMh8Meta?.name ?? "media-hub-hq-resources___it",
    path: hq_45resourcesZRV3C4GMh8Meta?.path ?? "/it/media-hub/hq-resources",
    meta: hq_45resourcesZRV3C4GMh8Meta || {},
    alias: hq_45resourcesZRV3C4GMh8Meta?.alias || [],
    redirect: hq_45resourcesZRV3C4GMh8Meta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/media-hub/hq-resources.vue").then(m => m.default || m)
  },
  {
    name: hq_45resourcesZRV3C4GMh8Meta?.name ?? "media-hub-hq-resources___zh",
    path: hq_45resourcesZRV3C4GMh8Meta?.path ?? "/zh/media-hub/hq-resources",
    meta: hq_45resourcesZRV3C4GMh8Meta || {},
    alias: hq_45resourcesZRV3C4GMh8Meta?.alias || [],
    redirect: hq_45resourcesZRV3C4GMh8Meta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/media-hub/hq-resources.vue").then(m => m.default || m)
  },
  {
    name: indexqmXcvk1NSfMeta?.name ?? "media-hub___de",
    path: indexqmXcvk1NSfMeta?.path ?? "/de/media-hub",
    meta: indexqmXcvk1NSfMeta || {},
    alias: indexqmXcvk1NSfMeta?.alias || [],
    redirect: indexqmXcvk1NSfMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/media-hub/index.vue").then(m => m.default || m)
  },
  {
    name: indexqmXcvk1NSfMeta?.name ?? "media-hub___en___default",
    path: indexqmXcvk1NSfMeta?.path ?? "/media-hub/",
    meta: indexqmXcvk1NSfMeta || {},
    alias: indexqmXcvk1NSfMeta?.alias || [],
    redirect: indexqmXcvk1NSfMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/media-hub/index.vue").then(m => m.default || m)
  },
  {
    name: indexqmXcvk1NSfMeta?.name ?? "media-hub___en",
    path: indexqmXcvk1NSfMeta?.path ?? "/en/media-hub",
    meta: indexqmXcvk1NSfMeta || {},
    alias: indexqmXcvk1NSfMeta?.alias || [],
    redirect: indexqmXcvk1NSfMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/media-hub/index.vue").then(m => m.default || m)
  },
  {
    name: indexqmXcvk1NSfMeta?.name ?? "media-hub___it",
    path: indexqmXcvk1NSfMeta?.path ?? "/it/media-hub",
    meta: indexqmXcvk1NSfMeta || {},
    alias: indexqmXcvk1NSfMeta?.alias || [],
    redirect: indexqmXcvk1NSfMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/media-hub/index.vue").then(m => m.default || m)
  },
  {
    name: indexqmXcvk1NSfMeta?.name ?? "media-hub___zh",
    path: indexqmXcvk1NSfMeta?.path ?? "/zh/media-hub",
    meta: indexqmXcvk1NSfMeta || {},
    alias: indexqmXcvk1NSfMeta?.alias || [],
    redirect: indexqmXcvk1NSfMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/media-hub/index.vue").then(m => m.default || m)
  },
  {
    name: login3sDf4UZJ80Meta?.name ?? "media-hub-login___de",
    path: login3sDf4UZJ80Meta?.path ?? "/de/media-hub/login",
    meta: login3sDf4UZJ80Meta || {},
    alias: login3sDf4UZJ80Meta?.alias || [],
    redirect: login3sDf4UZJ80Meta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/media-hub/login.vue").then(m => m.default || m)
  },
  {
    name: login3sDf4UZJ80Meta?.name ?? "media-hub-login___en___default",
    path: login3sDf4UZJ80Meta?.path ?? "/media-hub/login",
    meta: login3sDf4UZJ80Meta || {},
    alias: login3sDf4UZJ80Meta?.alias || [],
    redirect: login3sDf4UZJ80Meta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/media-hub/login.vue").then(m => m.default || m)
  },
  {
    name: login3sDf4UZJ80Meta?.name ?? "media-hub-login___en",
    path: login3sDf4UZJ80Meta?.path ?? "/en/media-hub/login",
    meta: login3sDf4UZJ80Meta || {},
    alias: login3sDf4UZJ80Meta?.alias || [],
    redirect: login3sDf4UZJ80Meta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/media-hub/login.vue").then(m => m.default || m)
  },
  {
    name: login3sDf4UZJ80Meta?.name ?? "media-hub-login___it",
    path: login3sDf4UZJ80Meta?.path ?? "/it/media-hub/login",
    meta: login3sDf4UZJ80Meta || {},
    alias: login3sDf4UZJ80Meta?.alias || [],
    redirect: login3sDf4UZJ80Meta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/media-hub/login.vue").then(m => m.default || m)
  },
  {
    name: login3sDf4UZJ80Meta?.name ?? "media-hub-login___zh",
    path: login3sDf4UZJ80Meta?.path ?? "/zh/media-hub/login",
    meta: login3sDf4UZJ80Meta || {},
    alias: login3sDf4UZJ80Meta?.alias || [],
    redirect: login3sDf4UZJ80Meta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/media-hub/login.vue").then(m => m.default || m)
  },
  {
    name: _91newsSlug_93qBLgYR6nugMeta?.name ?? "news-newsSlug___de",
    path: _91newsSlug_93qBLgYR6nugMeta?.path ?? "/de/nachrichten/:newsSlug",
    meta: _91newsSlug_93qBLgYR6nugMeta || {},
    alias: _91newsSlug_93qBLgYR6nugMeta?.alias || [],
    redirect: _91newsSlug_93qBLgYR6nugMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/news/[newsSlug].vue").then(m => m.default || m)
  },
  {
    name: _91newsSlug_93qBLgYR6nugMeta?.name ?? "news-newsSlug___en___default",
    path: _91newsSlug_93qBLgYR6nugMeta?.path ?? "/news/:newsSlug",
    meta: _91newsSlug_93qBLgYR6nugMeta || {},
    alias: _91newsSlug_93qBLgYR6nugMeta?.alias || [],
    redirect: _91newsSlug_93qBLgYR6nugMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/news/[newsSlug].vue").then(m => m.default || m)
  },
  {
    name: _91newsSlug_93qBLgYR6nugMeta?.name ?? "news-newsSlug___en",
    path: _91newsSlug_93qBLgYR6nugMeta?.path ?? "/en/news/:newsSlug",
    meta: _91newsSlug_93qBLgYR6nugMeta || {},
    alias: _91newsSlug_93qBLgYR6nugMeta?.alias || [],
    redirect: _91newsSlug_93qBLgYR6nugMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/news/[newsSlug].vue").then(m => m.default || m)
  },
  {
    name: _91newsSlug_93qBLgYR6nugMeta?.name ?? "news-newsSlug___it",
    path: _91newsSlug_93qBLgYR6nugMeta?.path ?? "/it/notizie/:newsSlug",
    meta: _91newsSlug_93qBLgYR6nugMeta || {},
    alias: _91newsSlug_93qBLgYR6nugMeta?.alias || [],
    redirect: _91newsSlug_93qBLgYR6nugMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/news/[newsSlug].vue").then(m => m.default || m)
  },
  {
    name: _91newsSlug_93qBLgYR6nugMeta?.name ?? "news-newsSlug___zh",
    path: _91newsSlug_93qBLgYR6nugMeta?.path ?? "/zh/xinwen/:newsSlug",
    meta: _91newsSlug_93qBLgYR6nugMeta || {},
    alias: _91newsSlug_93qBLgYR6nugMeta?.alias || [],
    redirect: _91newsSlug_93qBLgYR6nugMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/news/[newsSlug].vue").then(m => m.default || m)
  },
  {
    name: indexhc16fBo7rtMeta?.name ?? "news___de",
    path: indexhc16fBo7rtMeta?.path ?? "/de/nachrichten",
    meta: indexhc16fBo7rtMeta || {},
    alias: indexhc16fBo7rtMeta?.alias || [],
    redirect: indexhc16fBo7rtMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexhc16fBo7rtMeta?.name ?? "news___en___default",
    path: indexhc16fBo7rtMeta?.path ?? "/news/",
    meta: indexhc16fBo7rtMeta || {},
    alias: indexhc16fBo7rtMeta?.alias || [],
    redirect: indexhc16fBo7rtMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexhc16fBo7rtMeta?.name ?? "news___en",
    path: indexhc16fBo7rtMeta?.path ?? "/en/news",
    meta: indexhc16fBo7rtMeta || {},
    alias: indexhc16fBo7rtMeta?.alias || [],
    redirect: indexhc16fBo7rtMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexhc16fBo7rtMeta?.name ?? "news___it",
    path: indexhc16fBo7rtMeta?.path ?? "/it/notizie",
    meta: indexhc16fBo7rtMeta || {},
    alias: indexhc16fBo7rtMeta?.alias || [],
    redirect: indexhc16fBo7rtMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexhc16fBo7rtMeta?.name ?? "news___zh",
    path: indexhc16fBo7rtMeta?.path ?? "/zh/xinwen",
    meta: indexhc16fBo7rtMeta || {},
    alias: indexhc16fBo7rtMeta?.alias || [],
    redirect: indexhc16fBo7rtMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: successmu1pJRk9V4Meta?.name ?? "pardot-success___de",
    path: successmu1pJRk9V4Meta?.path ?? "/de/pardot/success",
    meta: successmu1pJRk9V4Meta || {},
    alias: successmu1pJRk9V4Meta?.alias || [],
    redirect: successmu1pJRk9V4Meta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/pardot/success.vue").then(m => m.default || m)
  },
  {
    name: successmu1pJRk9V4Meta?.name ?? "pardot-success___en___default",
    path: successmu1pJRk9V4Meta?.path ?? "/pardot/success",
    meta: successmu1pJRk9V4Meta || {},
    alias: successmu1pJRk9V4Meta?.alias || [],
    redirect: successmu1pJRk9V4Meta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/pardot/success.vue").then(m => m.default || m)
  },
  {
    name: successmu1pJRk9V4Meta?.name ?? "pardot-success___en",
    path: successmu1pJRk9V4Meta?.path ?? "/en/pardot/success",
    meta: successmu1pJRk9V4Meta || {},
    alias: successmu1pJRk9V4Meta?.alias || [],
    redirect: successmu1pJRk9V4Meta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/pardot/success.vue").then(m => m.default || m)
  },
  {
    name: successmu1pJRk9V4Meta?.name ?? "pardot-success___it",
    path: successmu1pJRk9V4Meta?.path ?? "/it/pardot/success",
    meta: successmu1pJRk9V4Meta || {},
    alias: successmu1pJRk9V4Meta?.alias || [],
    redirect: successmu1pJRk9V4Meta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/pardot/success.vue").then(m => m.default || m)
  },
  {
    name: successmu1pJRk9V4Meta?.name ?? "pardot-success___zh",
    path: successmu1pJRk9V4Meta?.path ?? "/zh/pardot/success",
    meta: successmu1pJRk9V4Meta || {},
    alias: successmu1pJRk9V4Meta?.alias || [],
    redirect: successmu1pJRk9V4Meta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/pardot/success.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policy5Dqs2dZEBXMeta?.name ?? "privacy-policy___de",
    path: privacy_45policy5Dqs2dZEBXMeta?.path ?? "/de/privacy-policy",
    meta: privacy_45policy5Dqs2dZEBXMeta || {},
    alias: privacy_45policy5Dqs2dZEBXMeta?.alias || [],
    redirect: privacy_45policy5Dqs2dZEBXMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policy5Dqs2dZEBXMeta?.name ?? "privacy-policy___en___default",
    path: privacy_45policy5Dqs2dZEBXMeta?.path ?? "/privacy-policy/",
    meta: privacy_45policy5Dqs2dZEBXMeta || {},
    alias: privacy_45policy5Dqs2dZEBXMeta?.alias || [],
    redirect: privacy_45policy5Dqs2dZEBXMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policy5Dqs2dZEBXMeta?.name ?? "privacy-policy___en",
    path: privacy_45policy5Dqs2dZEBXMeta?.path ?? "/en/privacy-policy",
    meta: privacy_45policy5Dqs2dZEBXMeta || {},
    alias: privacy_45policy5Dqs2dZEBXMeta?.alias || [],
    redirect: privacy_45policy5Dqs2dZEBXMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policy5Dqs2dZEBXMeta?.name ?? "privacy-policy___it",
    path: privacy_45policy5Dqs2dZEBXMeta?.path ?? "/it/privacy-policy",
    meta: privacy_45policy5Dqs2dZEBXMeta || {},
    alias: privacy_45policy5Dqs2dZEBXMeta?.alias || [],
    redirect: privacy_45policy5Dqs2dZEBXMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policy5Dqs2dZEBXMeta?.name ?? "privacy-policy___zh",
    path: privacy_45policy5Dqs2dZEBXMeta?.path ?? "/zh/privacy-policy",
    meta: privacy_45policy5Dqs2dZEBXMeta || {},
    alias: privacy_45policy5Dqs2dZEBXMeta?.alias || [],
    redirect: privacy_45policy5Dqs2dZEBXMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: _91projectSlug_9305MQPmuP1uMeta?.name ?? "projects-projectSlug___de",
    path: _91projectSlug_9305MQPmuP1uMeta?.path ?? "/de/projekte/:projectSlug",
    meta: _91projectSlug_9305MQPmuP1uMeta || {},
    alias: _91projectSlug_9305MQPmuP1uMeta?.alias || [],
    redirect: _91projectSlug_9305MQPmuP1uMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/projects/[projectSlug].vue").then(m => m.default || m)
  },
  {
    name: _91projectSlug_9305MQPmuP1uMeta?.name ?? "projects-projectSlug___en___default",
    path: _91projectSlug_9305MQPmuP1uMeta?.path ?? "/projects/:projectSlug",
    meta: _91projectSlug_9305MQPmuP1uMeta || {},
    alias: _91projectSlug_9305MQPmuP1uMeta?.alias || [],
    redirect: _91projectSlug_9305MQPmuP1uMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/projects/[projectSlug].vue").then(m => m.default || m)
  },
  {
    name: _91projectSlug_9305MQPmuP1uMeta?.name ?? "projects-projectSlug___en",
    path: _91projectSlug_9305MQPmuP1uMeta?.path ?? "/en/projects/:projectSlug",
    meta: _91projectSlug_9305MQPmuP1uMeta || {},
    alias: _91projectSlug_9305MQPmuP1uMeta?.alias || [],
    redirect: _91projectSlug_9305MQPmuP1uMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/projects/[projectSlug].vue").then(m => m.default || m)
  },
  {
    name: _91projectSlug_9305MQPmuP1uMeta?.name ?? "projects-projectSlug___it",
    path: _91projectSlug_9305MQPmuP1uMeta?.path ?? "/it/progetti/:projectSlug",
    meta: _91projectSlug_9305MQPmuP1uMeta || {},
    alias: _91projectSlug_9305MQPmuP1uMeta?.alias || [],
    redirect: _91projectSlug_9305MQPmuP1uMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/projects/[projectSlug].vue").then(m => m.default || m)
  },
  {
    name: _91projectSlug_9305MQPmuP1uMeta?.name ?? "projects-projectSlug___zh",
    path: _91projectSlug_9305MQPmuP1uMeta?.path ?? "/zh/xiangmu/:projectSlug",
    meta: _91projectSlug_9305MQPmuP1uMeta || {},
    alias: _91projectSlug_9305MQPmuP1uMeta?.alias || [],
    redirect: _91projectSlug_9305MQPmuP1uMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/projects/[projectSlug].vue").then(m => m.default || m)
  },
  {
    name: indexxI5TSxFy4VMeta?.name ?? "search___de",
    path: indexxI5TSxFy4VMeta?.path ?? "/de/suchen",
    meta: indexxI5TSxFy4VMeta || {},
    alias: indexxI5TSxFy4VMeta?.alias || [],
    redirect: indexxI5TSxFy4VMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexxI5TSxFy4VMeta?.name ?? "search___en___default",
    path: indexxI5TSxFy4VMeta?.path ?? "/search/",
    meta: indexxI5TSxFy4VMeta || {},
    alias: indexxI5TSxFy4VMeta?.alias || [],
    redirect: indexxI5TSxFy4VMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexxI5TSxFy4VMeta?.name ?? "search___en",
    path: indexxI5TSxFy4VMeta?.path ?? "/en/search",
    meta: indexxI5TSxFy4VMeta || {},
    alias: indexxI5TSxFy4VMeta?.alias || [],
    redirect: indexxI5TSxFy4VMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexxI5TSxFy4VMeta?.name ?? "search___it",
    path: indexxI5TSxFy4VMeta?.path ?? "/it/ricerca",
    meta: indexxI5TSxFy4VMeta || {},
    alias: indexxI5TSxFy4VMeta?.alias || [],
    redirect: indexxI5TSxFy4VMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexxI5TSxFy4VMeta?.name ?? "search___zh",
    path: indexxI5TSxFy4VMeta?.path ?? "/zh/sousuo",
    meta: indexxI5TSxFy4VMeta || {},
    alias: indexxI5TSxFy4VMeta?.alias || [],
    redirect: indexxI5TSxFy4VMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexHGRz3ODMtFMeta?.name ?? "sectors-sectorSlug___de",
    path: indexHGRz3ODMtFMeta?.path ?? "/de/sektoren/:sectorSlug",
    meta: indexHGRz3ODMtFMeta || {},
    alias: indexHGRz3ODMtFMeta?.alias || [],
    redirect: indexHGRz3ODMtFMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/sectors/[sectorSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexHGRz3ODMtFMeta?.name ?? "sectors-sectorSlug___en___default",
    path: indexHGRz3ODMtFMeta?.path ?? "/sectors/:sectorSlug/",
    meta: indexHGRz3ODMtFMeta || {},
    alias: indexHGRz3ODMtFMeta?.alias || [],
    redirect: indexHGRz3ODMtFMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/sectors/[sectorSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexHGRz3ODMtFMeta?.name ?? "sectors-sectorSlug___en",
    path: indexHGRz3ODMtFMeta?.path ?? "/en/sectors/:sectorSlug",
    meta: indexHGRz3ODMtFMeta || {},
    alias: indexHGRz3ODMtFMeta?.alias || [],
    redirect: indexHGRz3ODMtFMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/sectors/[sectorSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexHGRz3ODMtFMeta?.name ?? "sectors-sectorSlug___it",
    path: indexHGRz3ODMtFMeta?.path ?? "/it/settori/:sectorSlug",
    meta: indexHGRz3ODMtFMeta || {},
    alias: indexHGRz3ODMtFMeta?.alias || [],
    redirect: indexHGRz3ODMtFMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/sectors/[sectorSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexHGRz3ODMtFMeta?.name ?? "sectors-sectorSlug___zh",
    path: indexHGRz3ODMtFMeta?.path ?? "/zh/bumen/:sectorSlug",
    meta: indexHGRz3ODMtFMeta || {},
    alias: indexHGRz3ODMtFMeta?.alias || [],
    redirect: indexHGRz3ODMtFMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/sectors/[sectorSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: portfolioAKXTCsFfk7Meta?.name ?? "sectors-sectorSlug-portfolio___de",
    path: portfolioAKXTCsFfk7Meta?.path ?? "/de/sektoren/:sectorSlug/portfolio",
    meta: portfolioAKXTCsFfk7Meta || {},
    alias: portfolioAKXTCsFfk7Meta?.alias || [],
    redirect: portfolioAKXTCsFfk7Meta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/sectors/[sectorSlug]/portfolio.vue").then(m => m.default || m)
  },
  {
    name: portfolioAKXTCsFfk7Meta?.name ?? "sectors-sectorSlug-portfolio___en___default",
    path: portfolioAKXTCsFfk7Meta?.path ?? "/sectors/:sectorSlug/portfolio",
    meta: portfolioAKXTCsFfk7Meta || {},
    alias: portfolioAKXTCsFfk7Meta?.alias || [],
    redirect: portfolioAKXTCsFfk7Meta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/sectors/[sectorSlug]/portfolio.vue").then(m => m.default || m)
  },
  {
    name: portfolioAKXTCsFfk7Meta?.name ?? "sectors-sectorSlug-portfolio___en",
    path: portfolioAKXTCsFfk7Meta?.path ?? "/en/sectors/:sectorSlug/portfolio",
    meta: portfolioAKXTCsFfk7Meta || {},
    alias: portfolioAKXTCsFfk7Meta?.alias || [],
    redirect: portfolioAKXTCsFfk7Meta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/sectors/[sectorSlug]/portfolio.vue").then(m => m.default || m)
  },
  {
    name: portfolioAKXTCsFfk7Meta?.name ?? "sectors-sectorSlug-portfolio___it",
    path: portfolioAKXTCsFfk7Meta?.path ?? "/it/settori/:sectorSlug/portfolio",
    meta: portfolioAKXTCsFfk7Meta || {},
    alias: portfolioAKXTCsFfk7Meta?.alias || [],
    redirect: portfolioAKXTCsFfk7Meta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/sectors/[sectorSlug]/portfolio.vue").then(m => m.default || m)
  },
  {
    name: portfolioAKXTCsFfk7Meta?.name ?? "sectors-sectorSlug-portfolio___zh",
    path: portfolioAKXTCsFfk7Meta?.path ?? "/zh/bumen/:sectorSlug/wenjian-jia",
    meta: portfolioAKXTCsFfk7Meta || {},
    alias: portfolioAKXTCsFfk7Meta?.alias || [],
    redirect: portfolioAKXTCsFfk7Meta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/sectors/[sectorSlug]/portfolio.vue").then(m => m.default || m)
  },
  {
    name: _91serviceSlug_93jNNWsvHvexMeta?.name ?? "services-serviceSlug___de",
    path: _91serviceSlug_93jNNWsvHvexMeta?.path ?? "/de/dienstleistungen/:serviceSlug",
    meta: _91serviceSlug_93jNNWsvHvexMeta || {},
    alias: _91serviceSlug_93jNNWsvHvexMeta?.alias || [],
    redirect: _91serviceSlug_93jNNWsvHvexMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/services/[serviceSlug].vue").then(m => m.default || m)
  },
  {
    name: _91serviceSlug_93jNNWsvHvexMeta?.name ?? "services-serviceSlug___en___default",
    path: _91serviceSlug_93jNNWsvHvexMeta?.path ?? "/services/:serviceSlug",
    meta: _91serviceSlug_93jNNWsvHvexMeta || {},
    alias: _91serviceSlug_93jNNWsvHvexMeta?.alias || [],
    redirect: _91serviceSlug_93jNNWsvHvexMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/services/[serviceSlug].vue").then(m => m.default || m)
  },
  {
    name: _91serviceSlug_93jNNWsvHvexMeta?.name ?? "services-serviceSlug___en",
    path: _91serviceSlug_93jNNWsvHvexMeta?.path ?? "/en/services/:serviceSlug",
    meta: _91serviceSlug_93jNNWsvHvexMeta || {},
    alias: _91serviceSlug_93jNNWsvHvexMeta?.alias || [],
    redirect: _91serviceSlug_93jNNWsvHvexMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/services/[serviceSlug].vue").then(m => m.default || m)
  },
  {
    name: _91serviceSlug_93jNNWsvHvexMeta?.name ?? "services-serviceSlug___it",
    path: _91serviceSlug_93jNNWsvHvexMeta?.path ?? "/it/servizi/:serviceSlug",
    meta: _91serviceSlug_93jNNWsvHvexMeta || {},
    alias: _91serviceSlug_93jNNWsvHvexMeta?.alias || [],
    redirect: _91serviceSlug_93jNNWsvHvexMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/services/[serviceSlug].vue").then(m => m.default || m)
  },
  {
    name: _91serviceSlug_93jNNWsvHvexMeta?.name ?? "services-serviceSlug___zh",
    path: _91serviceSlug_93jNNWsvHvexMeta?.path ?? "/zh/fuwu/:serviceSlug",
    meta: _91serviceSlug_93jNNWsvHvexMeta || {},
    alias: _91serviceSlug_93jNNWsvHvexMeta?.alias || [],
    redirect: _91serviceSlug_93jNNWsvHvexMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/services/[serviceSlug].vue").then(m => m.default || m)
  },
  {
    name: indexTEG15DsSfqMeta?.name ?? "whistleblowing___de",
    path: indexTEG15DsSfqMeta?.path ?? "/de/whistleblowing",
    meta: indexTEG15DsSfqMeta || {},
    alias: indexTEG15DsSfqMeta?.alias || [],
    redirect: indexTEG15DsSfqMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/whistleblowing/index.vue").then(m => m.default || m)
  },
  {
    name: indexTEG15DsSfqMeta?.name ?? "whistleblowing___en___default",
    path: indexTEG15DsSfqMeta?.path ?? "/whistleblowing/",
    meta: indexTEG15DsSfqMeta || {},
    alias: indexTEG15DsSfqMeta?.alias || [],
    redirect: indexTEG15DsSfqMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/whistleblowing/index.vue").then(m => m.default || m)
  },
  {
    name: indexTEG15DsSfqMeta?.name ?? "whistleblowing___en",
    path: indexTEG15DsSfqMeta?.path ?? "/en/whistleblowing",
    meta: indexTEG15DsSfqMeta || {},
    alias: indexTEG15DsSfqMeta?.alias || [],
    redirect: indexTEG15DsSfqMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/whistleblowing/index.vue").then(m => m.default || m)
  },
  {
    name: indexTEG15DsSfqMeta?.name ?? "whistleblowing___it",
    path: indexTEG15DsSfqMeta?.path ?? "/it/whistleblowing",
    meta: indexTEG15DsSfqMeta || {},
    alias: indexTEG15DsSfqMeta?.alias || [],
    redirect: indexTEG15DsSfqMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/whistleblowing/index.vue").then(m => m.default || m)
  },
  {
    name: indexTEG15DsSfqMeta?.name ?? "whistleblowing___zh",
    path: indexTEG15DsSfqMeta?.path ?? "/zh/whistleblowing",
    meta: indexTEG15DsSfqMeta || {},
    alias: indexTEG15DsSfqMeta?.alias || [],
    redirect: indexTEG15DsSfqMeta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/pages/whistleblowing/index.vue").then(m => m.default || m)
  },
  {
    name: pininfarina_45zh_46quattrolineeo2BPDcoM46Meta?.name ?? undefined,
    path: pininfarina_45zh_46quattrolineeo2BPDcoM46Meta?.path ?? "/sitemap.xml",
    meta: pininfarina_45zh_46quattrolineeo2BPDcoM46Meta || {},
    alias: pininfarina_45zh_46quattrolineeo2BPDcoM46Meta?.alias || [],
    redirect: pininfarina_45zh_46quattrolineeo2BPDcoM46Meta?.redirect,
    component: () => import("/home/forge/pininfarina-zh.quattrolinee.com/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  }
]