import revive_payload_client_4sVQNw7RlN from "/home/forge/pininfarina-zh.quattrolinee.com/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/forge/pininfarina-zh.quattrolinee.com/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/forge/pininfarina-zh.quattrolinee.com/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "/home/forge/pininfarina-zh.quattrolinee.com/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "/home/forge/pininfarina-zh.quattrolinee.com/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/forge/pininfarina-zh.quattrolinee.com/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/forge/pininfarina-zh.quattrolinee.com/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/forge/pininfarina-zh.quattrolinee.com/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/forge/pininfarina-zh.quattrolinee.com/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_w2VlvAEcpa from "/home/forge/pininfarina-zh.quattrolinee.com/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import composition_sLxaNGmlSL from "/home/forge/pininfarina-zh.quattrolinee.com/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/home/forge/pininfarina-zh.quattrolinee.com/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/home/forge/pininfarina-zh.quattrolinee.com/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import cross_origin_prefetch_client_UzIc2qvBnC from "/home/forge/pininfarina-zh.quattrolinee.com/node_modules/nuxt/dist/app/plugins/cross-origin-prefetch.client.js";
import plugin_1UohGbtF8v from "/home/forge/pininfarina-zh.quattrolinee.com/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import event_bus_JNcXIfrhYt from "/home/forge/pininfarina-zh.quattrolinee.com/plugins/event-bus.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_w2VlvAEcpa,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  cross_origin_prefetch_client_UzIc2qvBnC,
  plugin_1UohGbtF8v,
  event_bus_JNcXIfrhYt
]